import React from 'react'
import '../css/Card.css'

function Card(props) {
    console.log(props.content)
    return (
        <div className={`card ${props.content === 'top' ? 'top' : 'question'}`}>
            {props.content === 'top' ? (
                <React.Fragment>
                    <h2 className="title">
                        <span className="pod">Pod</span>
                        <span className="decks">decks</span>
                    </h2>
                    <h5 className="card-type">Interview Deck</h5>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <div className="question">
                        <h3 className="question-text">{props.content}</h3>
                    </div>
                </React.Fragment>
            )}
        </div>
    )
}

export default Card