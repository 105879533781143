import React, {Component} from 'react';
import Card from './components/Card'
import PoddecksLogo from './img/poddecks_logo.png'
import './css/normalize.css'
import './App.css';
import InterviewDeckData from './data/interviewDeck.json'

class App extends Component {
  state = {
    // chosen: [],
    currentCard: 'top'
  }

  chooseNewCard = () => {
    console.log(InterviewDeckData)
    let questionText
    const random = Math.floor(Math.random() * InterviewDeckData.length)
    
    // const found = InterviewDeckData.some(question => question.id == random)
    InterviewDeckData.forEach(question => {
      if (question.id === random) {
        questionText = question.question
        this.setState({currentCard: questionText})
      }
    })

    console.log('question: ', questionText)
  }

  render () {
    return (
      <div className="App">
        <div className="header">
          <img className="header--logo" src={PoddecksLogo} alt="Poddecks Logo"/>
        </div>
        <div className="card-container">
          <Card content={this.state.currentCard}></Card>
        </div>
        <div className="card-button" onClick={this.chooseNewCard}>Choose Card</div>
      </div>
    )
  }
  
}

export default App;
